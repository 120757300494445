















































































































import Vue from "vue";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { ApiException, UserAccountUpdateEmailCommand } from "@/clients/clients";
import AppLoading from "@/components/layout/AppLoading.vue";
import { required, email } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";

setInteractionMode("eager");

extend("required_field", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("email", {
  ...email,
  message: "{_field_} must be a valid email",
});

export default Vue.extend({
  name: "ProfileEmailInformationView",
  components: { AppLoading, ValidationObserver, ValidationProvider },
  data: () => ({
    errorMessage: "",
    dialog: false,
    updated: false,
    loading: false,
    form: new UserAccountUpdateEmailCommand(),
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
  },
  created() {
    const data = new UserAccountUpdateEmailCommand();
    data.init(this.user);
    this.form = Object.assign({}, this.form, data);
  },
  methods: {
    ...mapActions("UserStore", ["updateEmail"]),
    onSubmit() {
      this.dialog = true;
    },
    async update() {
      this.dialog = false;
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.updateEmail(this.form);
        this.form = Object.assign({}, this.form, { email: this.user.email });
        this.updated = true;
        await this.$auth.logout();
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
